import { BreadcrumbGroupProps, Button, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { eEntityStatus } from 'src/constants/generic-constants';
import { AuditLogEntity, AuditTableDefinitionsProps } from 'src/models/AppContextModels';
import { DynamicColumnDetails } from 'src/models/XptTableModel';
import { formatUTCAsLocalReadable } from 'src/utils/date-time-utilities';
import { getStatusIndicatorColorOverride, getStatusIndicatorType } from 'src/utils/generic-utilities';
import { AdminBaseBreadcrumbs } from '../../AdminConsole';

export const geAuditLogBreadcrumbItems = (): BreadcrumbGroupProps.Item[] => {
  return [
    ...AdminBaseBreadcrumbs,
    {
      text: 'Audit Log',
      href: '/admin-console/audit-log'
    }
  ];
};

export const AUDIT_TABLE_CONFIG: DynamicColumnDetails = {
  columnInfo: {
    entityName: 'Audit Log',
    trackBy: 'audit_log_id',
    sortingColumn: 'created_at',
    isDescending: true
  },
  data: [
    {
      fieldName: 'page_name',
      displayName: 'Page',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'operation_type',
      displayName: 'Operation Type',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'status',
      displayName: 'Status',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'record_count',
      displayName: 'Effected Records',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'number'
      }
    },
    {
      fieldName: 'created_at',
      displayName: 'Performed At',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: false,
      metadata: {
        type: 'dateTime'
      }
    },
    {
      fieldName: 'created_by',
      displayName: 'Performed By',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'dateTime'
      }
    },
    {
      fieldName: 'additional_info',
      displayName: 'Additional Info',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: false,
      metadata: {
        type: 'string'
      }
    }
  ]
};

export const getAuditTableDefinitions = ({
  handleAdditionalInfoClick
}: AuditTableDefinitionsProps): TableProps.ColumnDefinition<AuditLogEntity>[] => [
  {
    id: 'audit_log_id',
    header: 'Id',
    cell: (item: AuditLogEntity) => item.audit_log_id,
    sortingField: 'audit_log_id'
  },
  {
    id: 'page_name',
    header: 'Page Name',
    cell: (item: AuditLogEntity) => item.page_name,
    sortingField: 'page_name',
    isRowHeader: true
  },
  {
    id: 'operation_type',
    header: 'Operation Type',
    cell: (item: AuditLogEntity) => item.operation_type,
    sortingField: 'operation_type',
    isRowHeader: true
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item: AuditLogEntity) => {
      if (!item.status) {
        // Handling the null case, e.g., by displaying a default value or a specific UI element
        return <div>-</div>;
      }
      return (
        <StatusIndicator
          type={getStatusIndicatorType(item.status as eEntityStatus)}
          colorOverride={getStatusIndicatorColorOverride(item.status as eEntityStatus)}
        >
          {item.status}
        </StatusIndicator>
      );
    },
    sortingField: 'status',
    isRowHeader: true
  },
  {
    id: 'record_count',
    header: 'Effected Records',
    cell: (item: AuditLogEntity) => item.record_count || '-',
    sortingField: 'record_count',
    isRowHeader: true
  },
  {
    id: 'created_at',
    header: 'Performed At',
    cell: (item: AuditLogEntity) => formatUTCAsLocalReadable(item.created_at, true),
    sortingField: 'created_at',
    isRowHeader: true
  },
  {
    id: 'created_by',
    header: 'Performed By',
    cell: (item: AuditLogEntity) => item.created_by,
    sortingField: 'created_by',
    isRowHeader: true
  },
  {
    id: 'additional_info',
    header: 'Additional Info',
    cell: (item: AuditLogEntity) =>
      item.additional_info ? (
        <Button variant="inline-link" ariaLabel={`Open ${item.additional_info}`} onClick={() => handleAdditionalInfoClick(item.audit_log_id)}>
          Show Details
        </Button>
      ) : (
        <></>
      ),
    sortingField: 'additional_info',
    isRowHeader: true
  }
];
