import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserAccessAuthorization } from 'src/api/app-sync-services';
import { Status } from 'src/models/AuthContextModels';
import { CurrentUserAccessDetails, UserAccessEntity, UserAccessForCurrentBusinessGroup } from 'src/models/XptUsersModel';
import { RootState } from 'src/store/store';
import { getCurrentUserAccessDetails, getUserCostCenters } from './AccessAuthorizationUtils';

interface AccessAndAuthorizationState {
  xptUsersLoadingStatus: Status;
  xptUsers: UserAccessEntity[];

  // currentUserAccessDetails is widely used at Admin Console / Dev / where user is not in particular Business Group
  currentUserAccessLoadingStatus: Status;
  currentUserAccessDetails: CurrentUserAccessDetails | null;
  currentUserNotFound: boolean;

  // Once user is in particular business group, userAccessForCurrentBusinessGroup is utilized
  userAccessForCurrentBusinessGroup: UserAccessForCurrentBusinessGroup | null;
  userCostCentersForCurrentBusinessGroup: string[];
  userAccessForCurrentBusinessGroupStatus: Status;
}

const initialState: AccessAndAuthorizationState = {
  xptUsers: [],
  xptUsersLoadingStatus: Status.NotInitiated,

  currentUserAccessLoadingStatus: Status.NotInitiated,
  currentUserAccessDetails: null,
  currentUserNotFound: false,

  userAccessForCurrentBusinessGroup: null,
  userCostCentersForCurrentBusinessGroup: [],
  userAccessForCurrentBusinessGroupStatus: Status.NotInitiated
};

// Async thunk to fetch all user access and authorization data
export const fetchUserAccessAndAuthorization = createAsyncThunk<
  UserAccessEntity[],
  { userAlias: string; isInitiallyDev: boolean },
  { rejectValue: string }
>('accessAndAuthorization/fetchUserAccessAndAuthorization', async ({ userAlias, isInitiallyDev }, { rejectWithValue }) => {
  try {
    const userAccessData: UserAccessEntity[] = await getUserAccessAuthorization();

    // Since developer access is being used from "Access Controller" (Modal popup), system wont consider user information from here.
    if (isInitiallyDev) {
      const filteredUsersList = userAccessData.filter((userAccess) => userAccess.user_alias !== userAlias);
      return filteredUsersList;
    }

    return userAccessData;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
    return rejectWithValue(`Failed to fetch user access and authorization data: ${errorMessage}`);
  }
});

// Async thunk to fetch current user authorization data
export const fetchCurrentUserAuthorization = createAsyncThunk<
  { currentUserAccessDetails: CurrentUserAccessDetails | null; currentUserNotFound: boolean },
  { userAlias: string; isDev: boolean },
  { state: RootState; rejectValue: string }
>('accessAndAuthorization/fetchCurrentUserAuthorization', async ({ userAlias, isDev }, { getState, rejectWithValue }) => {
  try {
    // Get the state from the store
    const state = getState();
    const currentUsersPermissionFromAllBusinessGroups = state.xptAccessAndAuthorizationStore.xptUsers.filter(
      (user) => user.user_alias?.toLowerCase() === userAlias?.toLowerCase() && user.is_active
    );

    const businessGroups = state.businessGroupStore.businessGroups;
    const activeBusinessGroups = businessGroups.filter((group) => group.item_metadata.is_active);

    const costCenterForAllBusinessGroups = state.corpSegmentsStore.masterCostCenterDropdownValuesForAllBusinessGroups;

    // Call the utility function
    const currentUserAccessDetails = getCurrentUserAccessDetails(
      userAlias,
      isDev,
      currentUsersPermissionFromAllBusinessGroups,
      activeBusinessGroups,
      costCenterForAllBusinessGroups
    );

    if (!currentUserAccessDetails.currentUserAccessDetails) {
      return rejectWithValue(`User with alias ${userAlias} not found.`);
    }

    // Return the details as expected by the thunk's fulfilled case
    return currentUserAccessDetails;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
    return rejectWithValue(`Failed to fetch current user details: ${errorMessage}`);
  }
});

const accessAndAuthorizationSlice = createSlice({
  name: 'accessAndAuthorization',
  initialState,
  reducers: {
    // Loads at Business Group Wrapper
    setUserAccessForCurrentBusinessGroup(state, action: PayloadAction<UserAccessForCurrentBusinessGroup | null>) {
      state.userAccessForCurrentBusinessGroup = action.payload;
      state.userCostCentersForCurrentBusinessGroup = action.payload ? getUserCostCenters(action.payload) : [];
    },
    // Loads at Business Group Wrapper
    setUserAccessForCurrentBusinessGroupStatus(state, action: PayloadAction<Status>) {
      state.userAccessForCurrentBusinessGroupStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchUserAccessAndAuthorization
      .addCase(fetchUserAccessAndAuthorization.pending, (state) => {
        state.xptUsers = [];
        state.xptUsersLoadingStatus = Status.Loading;
      })
      .addCase(fetchUserAccessAndAuthorization.fulfilled, (state, action: PayloadAction<UserAccessEntity[]>) => {
        state.xptUsers = action.payload;
        state.xptUsersLoadingStatus = Status.Completed;
      })
      .addCase(fetchUserAccessAndAuthorization.rejected, (state, action) => {
        state.xptUsers = [];
        state.xptUsersLoadingStatus = Status.Failed;
      })
      // Handle fetchCurrentUserAuthorization
      .addCase(fetchCurrentUserAuthorization.pending, (state) => {
        state.currentUserAccessDetails = null;
        state.currentUserNotFound = false;
        state.currentUserAccessLoadingStatus = Status.Loading;
      })
      .addCase(
        fetchCurrentUserAuthorization.fulfilled,
        (state, action: PayloadAction<{ currentUserAccessDetails: CurrentUserAccessDetails | null; currentUserNotFound: boolean }>) => {
          state.currentUserAccessDetails = action.payload.currentUserAccessDetails;
          state.currentUserNotFound = action.payload.currentUserNotFound;
          state.currentUserAccessLoadingStatus = Status.Completed;
        }
      )
      .addCase(fetchCurrentUserAuthorization.rejected, (state, action) => {
        state.currentUserAccessDetails = null;
        state.currentUserNotFound = true;
        state.currentUserAccessLoadingStatus = Status.Failed;
      });
  }
});

export const { setUserAccessForCurrentBusinessGroup, setUserAccessForCurrentBusinessGroupStatus } = accessAndAuthorizationSlice.actions;
export default accessAndAuthorizationSlice.reducer;
