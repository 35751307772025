import { Box, ContentLayout, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logUserClick } from 'src/analytics/KatalLogger';
import { DataClassification } from 'src/models/AppContextModels';
import { Status } from 'src/models/AuthContextModels';
import { RootState } from 'src/store/store';
import { compareNullableNumbers } from 'src/utils/generic-utilities';
import { selectActiveBusinessGroups } from '../business-group/businessGroupSelectors';

export const useBusinessGroupNameFromPath = () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const businessGroupName = pathParts[1];
  return businessGroupName;
};

export const XptSecondaryNavBar: React.FC<any> = () => {
  const navigate = useNavigate();

  const { currentUserAccessLoadingStatus, currentUserAccessDetails } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);

  const businessGroupsLoading = useSelector((state: RootState) => state.businessGroupStore.businessGroupLoadingStatus);
  const activeBusinessGroups = useSelector(selectActiveBusinessGroups);

  const [dataClassifications, setDataClassifications] = useState<DataClassification[]>([]);
  const businessGroupName = useBusinessGroupNameFromPath();

  useEffect(() => {
    if (currentUserAccessLoadingStatus === Status.Completed && businessGroupsLoading === Status.Completed) {
      const activeDataClassifications = activeBusinessGroups.map((_) => _.data_classification);
      const dataClassificationsThatAreAuthorizedToUser = currentUserAccessDetails?.user_data_classifications.map((dt) => dt.data_classification_id);
      const authorizedDataClassifications = activeDataClassifications.filter((dt) =>
        dataClassificationsThatAreAuthorizedToUser?.includes(dt.data_classification_id!)
      );
      setDataClassifications(authorizedDataClassifications);
    }
  }, [currentUserAccessLoadingStatus, businessGroupsLoading]);

  const onClickOfDataClassification = (dataClassification: DataClassification) => {
    logUserClick(`Selected ${dataClassification.data_classification_name} in Navigation`, 'Top Navigation', 'Landing Page');
    navigate('/' + dataClassification.data_classification_name + '/forecast-input');
  };

  return (
    <ContentLayout className="app-secondary-top-navigation">
      <SpaceBetween direction="horizontal" size="s" className="nav-items-container">
        {dataClassifications
          ?.sort((a, b) => compareNullableNumbers(a.data_classification_id, b.data_classification_id, 'asc'))
          ?.map((dataClassification: DataClassification, index: number) => {
            const isHighlighted = businessGroupName === dataClassification.data_classification_name;
            return (
              <div
                className={`global-secondary-nav-item ${isHighlighted ? 'highlighted-nav-item' : ''}`}
                key={index}
                onClick={() => onClickOfDataClassification(dataClassification)}
              >
                <span className="display-name">{dataClassification.data_classification_name}</span>
              </div>
            );
          })}
      </SpaceBetween>
    </ContentLayout>
  );
};
