import {
  Badge,
  Box,
  Button,
  Form,
  FormField,
  Input,
  Multiselect,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  SplitPanel,
  StatusIndicator,
  Toggle
} from '@amzn/awsui-components-react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { createOrUpdateUserAccessMutation } from 'src/api/app-sync-services';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { UserDisplay } from 'src/components/common/UserComponents';
import { CorpSegmentNames } from 'src/constants/corp-segment-constants';
import { FINANCE_ADMIN, SELECT_ALL_OPTION, USER_TYPES, UserValidationMessages } from 'src/constants/generic-constants';
import { splitPanelI18nStrings } from 'src/i18n-strings';
import { Status } from 'src/models/AuthContextModels';
import { ValidateUserAliasResponse } from 'src/models/PeopleApiModel';
import { UserAccessEntity, UserAccessForm } from 'src/models/XptUsersModel';
import { RootState } from 'src/store/store';
import { sendSNSNotification } from 'src/utils/aws-sns-service';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { getMultiSelectPlaceHolderValue } from 'src/utils/generic-utilities';
import { validateUserAliasWithPAPI } from 'src/utils/papi-services';
import { currentBusinessGroupName, currentDataClassificationId } from '../businessGroupSelectors';
import { convertEntityToForm, convertFormToEntityValues, userAccessValidationSchema } from './AccessModificationFormUtils';

interface UserAccessModificationFormProps {
  selectedUser: UserAccessEntity[];
  userAccessFormSubmitResponse: (isNewUser: boolean, userAlias: string, status: Status) => void;
}

const UserAccessModificationForm: React.FC<UserAccessModificationFormProps> = ({ selectedUser, userAccessFormSubmitResponse }) => {
  const { userAlias, isDev } = useAuth();

  const [isNewUser, setIsNewUser] = useState<boolean>(true);
  const { userAccessForCurrentBusinessGroup } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
  // FinanceAdmin Or FinanceOwner only can access this page.
  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup ? userAccessForCurrentBusinessGroup.isFinanceAdminOrFinanceOwner : false;
  const dataClassificationId = useSelector(currentDataClassificationId);
  const businessGroupName = useSelector(currentBusinessGroupName);

  const [currentUserEntity, setCurrentUserEntity] = useState<UserAccessEntity>(selectedUser[0]);

  const { masterCorpSegmentDropdownValues, masterCorpSegmentDropdownValuesStatus } = useSelector((state: RootState) => state.corpSegmentsStore);
  const xptUsers = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore.xptUsers);

  const EMPTY_FORM: UserAccessForm = {
    access_id: null,
    data_classification_id: dataClassificationId,
    user_alias: '',
    is_valid_user_alias: false,
    user_name: '',
    user_type: '',
    cost_centers: [],
    not_applicable_cost_centers: [],
    is_active: true,
    created_at: getCurrentUTCTimeInISO(),
    created_by: userAlias,
    updated_at: getCurrentUTCTimeInISO(),
    updated_by: userAlias
  };

  const [formInitializing, setFormInitializing] = useState<boolean>(true);
  const [userAccessForm, setUserAccessForm] = useState<UserAccessForm>(EMPTY_FORM);
  const userAccessFormRef = useRef<FormikProps<UserAccessForm>>(null);
  const [userAccessFormSubmitStatus, setUserAccessFormSubmitStatus] = useState<Status>(Status.NotInitiated);

  const [costCenterOptions, setCostCenterOptions] = useState<SelectProps.OptionGroup[]>([]);
  const [userAliasValidationStatus, setUserAliasValidationStatus] = useState<Status>(Status.NotInitiated);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    const costCenter = masterCorpSegmentDropdownValues.find(
      (corpSegment) => corpSegment.masterCorpSegmentDisplayName === CorpSegmentNames.COST_CENTER
    );
    if (costCenter) {
      setCostCenterOptions([{ ...SELECT_ALL_OPTION, options: costCenter.masterCorpSegmentDropdownValues }]);
    } else {
      setCostCenterOptions([]);
    }
  }, [masterCorpSegmentDropdownValues]);

  // Set the header based on whether we are editing or adding a new user
  useEffect(() => {
    if (selectedUser.length > 0) {
      setIsNewUser(false);
      setCurrentUserEntity(selectedUser[0]);
      handleFormInitialization(selectedUser[0]);

      setUserAliasValidationStatus(Status.Completed);
      setValidationMessage(UserValidationMessages.UserIsValid);
    } else {
      setIsNewUser(true);
      handleFormInitialization();

      setUserAliasValidationStatus(Status.NotInitiated);
      setValidationMessage(UserValidationMessages.UserAliasValidationNotInitiated);
    }
  }, [selectedUser, costCenterOptions]);

  const handleFormInitialization = (currentUser?: UserAccessEntity) => {
    const initialFormValues = currentUser ? convertEntityToForm(currentUser, costCenterOptions) : EMPTY_FORM;
    setUserAccessForm(initialFormValues);

    // Reset and reinitialize the form with the new values
    userAccessFormRef.current?.resetForm({ values: initialFormValues });

    setFormInitializing(false);
  };

  const getUserTypes = () => {
    const userTypeOptions = USER_TYPES.map((userType) => ({ label: userType, value: userType }));
    return isDev ? userTypeOptions : userTypeOptions.filter((userType) => userType.label !== FINANCE_ADMIN);
  };

  // Validate the user alias using the PAPI service
  const validateAlias = async (userInput: string) => {
    if (!userInput) return;

    // Check if user alias already exists
    const userAliasFound = xptUsers.find(
      (user) => user.data_classification_id === dataClassificationId && user.user_alias?.toLowerCase() === userInput?.toLowerCase()
    );
    if (userAliasFound) {
      setIsNewUser(false);
      setCurrentUserEntity(userAliasFound);
      setUserAliasValidationStatus(Status.Completed);
      setValidationMessage(UserValidationMessages.UserIsValid);

      handleFormInitialization(userAliasFound);
      return;
    }

    setUserAliasValidationStatus(Status.Validating);
    try {
      const response: ValidateUserAliasResponse = await validateUserAliasWithPAPI(userInput);
      const userName = `${response.userInfo?.firstName} ${response.userInfo?.lastName}`;
      userAccessFormRef?.current?.setFieldValue('is_valid_user_alias', response.isValid);
      userAccessFormRef?.current?.setFieldValue('user_name', userName); // Populate user_name after validation
      setValidationMessage(response.validationMessage);
    } catch (error: any) {
      logger.error(`Alias validation failed using PAPI for ${userInput}`, error);
      userAccessFormRef?.current?.setFieldValue('is_valid_user_alias', false);
      setValidationMessage('Alias is invalid.');
    } finally {
      setUserAliasValidationStatus(Status.Completed);
    }
  };

  const handleSubmit = async (formValues: UserAccessForm, formikHelpers: FormikHelpers<UserAccessForm>) => {
    setUserAccessFormSubmitStatus(Status.Loading);
    const accessAndAuthorizationMutation = convertFormToEntityValues(userAlias, formValues, currentUserEntity);

    try {
      const response = await createOrUpdateUserAccessMutation(accessAndAuthorizationMutation);
      const numberOfRecordsUpdated = response?.createOrUpdateUserAccess?.numberOfRecordsUpdated;
      if (numberOfRecordsUpdated === 1) {
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm({ values: EMPTY_FORM });
        setUserAliasValidationStatus(Status.NotInitiated);
        setUserAccessFormSubmitStatus(Status.Completed);

        logger.info(`User ${userAlias} was successfully ${isNewUser ? 'added' : 'updated'}`);
        logger.info(
          `Access permissions for user ${accessAndAuthorizationMutation.user_alias} were modified by ${userAlias} in ${businessGroupName}`,
          { response: response }
        );
        sendSNSNotification(
          `Access Permissions Updated`,
          `Access permissions for user ${accessAndAuthorizationMutation.user_alias} were successfully modified by ${userAlias} in ${businessGroupName}`,
          'success'
        );
        userAccessFormSubmitResponse(isNewUser, accessAndAuthorizationMutation.user_alias, Status.Completed);
      } else {
        sendSNSNotification(
          `Access Update Failed`,
          `Failed to update access permissions for user ${accessAndAuthorizationMutation.user_alias} by ${userAlias} in ${businessGroupName}`,
          'error'
        );
        logger.error('Access permissions update failed', { error: 'Invalid number of records updated in response: ' + response });
        setUserAccessFormSubmitStatus(Status.Failed);
      }
    } catch (error: any) {
      sendSNSNotification(
        `Access Update Failed`,
        `Failed to update access permissions for user ${accessAndAuthorizationMutation.user_alias} by ${userAlias} in ${businessGroupName}`,
        'error'
      );
      logger.error('Access permissions update failed', { error: error });
      setUserAccessFormSubmitStatus(Status.Failed);
    }
  };

  return (
    <SplitPanel hidePreferencesButton header={isNewUser ? 'New User' : 'Edit User'} i18nStrings={splitPanelI18nStrings}>
      {formInitializing && <LoadingSpinner />}
      {!formInitializing && (
        <Formik<UserAccessForm>
          innerRef={userAccessFormRef}
          enableReinitialize={true}
          initialValues={userAccessForm}
          validationSchema={userAccessValidationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {({ errors, handleBlur, handleSubmit, touched, dirty, isValid, setFieldTouched, values, setFieldValue, isSubmitting }) => {
            return (
              <>
                <Form>
                  <SpaceBetween size="m" direction="vertical">
                    <FormField label="Alias" errorText={touched.user_alias && errors.user_alias} constraintText="Alias will be converted to lowercase.">
                      <Input
                        autoComplete={false}
                        name="user_alias"
                        value={values.user_alias}
                        readOnly={userAliasValidationStatus === Status.Validating || !isNewUser}
                        onChange={({ detail }) => {
                          setFieldValue('user_alias', detail.value.toLowerCase());
                        }}
                        onBlur={() => {
                          setFieldValue('is_valid_user_alias', false);
                          validateAlias(values.user_alias);
                        }}
                      />
                    </FormField>

                    {userAliasValidationStatus === Status.NotInitiated && (
                      <Badge color={'grey'}>{UserValidationMessages.UserAliasValidationNotInitiated}</Badge>
                    )}
                    {userAliasValidationStatus === Status.Validating && (
                      <Box margin="xxxs" padding="xxxs" textAlign="left">
                        <SpaceBetween size="m" direction="horizontal">
                          <Spinner size="normal" />
                          <Badge color="grey">Validating</Badge>
                        </SpaceBetween>
                      </Box>
                    )}
                    {userAliasValidationStatus === Status.Completed && (
                      <Badge color={values.is_valid_user_alias ? 'green' : 'red'}>{validationMessage}</Badge>
                    )}

                    <FormField label="Name" errorText={touched.user_name && errors.user_name} stretch>
                      {values.is_valid_user_alias && (
                        <UserDisplay
                          userAlias={values.user_alias}
                          userName={values.user_name}
                          variant="medium"
                          imgSize={30}
                          page="Access & Authorization"
                          mainPage={businessGroupName}
                        />
                      )}
                    </FormField>

                    <FormField label="User Type" errorText={touched.user_type && errors.user_type}>
                      <Select
                        selectedOption={{ label: values.user_type, value: values.user_type }}
                        options={getUserTypes()}
                        onChange={({ detail }) => setFieldValue('user_type', detail.selectedOption.value)}
                        disabled={!isFinanceAdminOrFinanceOwner && values.user_type === FINANCE_ADMIN}
                      />
                    </FormField>

                    <FormField label="Status" errorText={touched.is_active && errors.is_active}>
                      <Toggle
                        checked={values.is_active}
                        onChange={({ detail }) => setFieldValue('is_active', detail.checked)}
                        disabled={!isFinanceAdminOrFinanceOwner && values.user_type === FINANCE_ADMIN}
                      >
                        {values.is_active ? 'Active' : 'Inactive'}
                      </Toggle>
                    </FormField>

                    <FormField label="Cost Center" errorText={touched.cost_centers && errors.cost_centers}>
                      <Multiselect
                        expandToViewport
                        filteringType="auto"
                        disabled={!isFinanceAdminOrFinanceOwner && values.user_type === FINANCE_ADMIN}
                        selectedOptions={values.cost_centers.map((center) => ({
                          label: center,
                          value: center
                        }))}
                        onChange={({ detail }) =>
                          setFieldValue(
                            'cost_centers',
                            detail.selectedOptions.map((option) => option.value)
                          )
                        }
                        options={costCenterOptions}
                        hideTokens
                        errorText={masterCorpSegmentDropdownValuesStatus === Status.Failed ? 'Cost Center loading failed' : undefined}
                        placeholder={getMultiSelectPlaceHolderValue(
                          values.cost_centers.map((center) => ({
                            label: center,
                            value: center
                          })),
                          'Cost Center'
                        )}
                      />
                    </FormField>

                    <Button variant="primary" onClick={() => handleSubmit()} disabled={isSubmitting || !dirty}>
                      {!isNewUser ? 'Update User' : 'Add User'}
                    </Button>

                    {userAccessFormSubmitStatus === Status.Failed && (
                      <Box>
                        <StatusIndicator type="error">{`Unable to submit request`}</StatusIndicator>
                      </Box>
                    )}
                  </SpaceBetween>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </SplitPanel>
  );
};

export default UserAccessModificationForm;
