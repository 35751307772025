import { logger } from 'src/analytics/KatalLogger';
import { BusinessSegmentNames, CorpSegmentNames } from 'src/constants/corp-segment-constants';
import {
  getUserCostCenters,
  getUserCostCentersFromUserAccessEntitiesList
} from 'src/features/business-group/access-authorization/redux/AccessAuthorizationUtils';
import { MasterBusinessSegments } from 'src/models/AppContextModels';
import {
  ForecastGridRowData,
  ForecastTemplateColumns,
  ForecastTemplateDataValidationStatus,
  ForecastTemplateMasterCorpSegmentDropdownValues,
  VALIDATION_NOT_INITIATED
} from 'src/models/ForecastModels';
import { ValidationErrorDetail, ValidationStatusEntity } from 'src/models/XptGenericModels';
import { AccountBudgetTypeMapping } from 'src/models/xPTMappingModels';
import { UserAccessEntity, UserAccessForCurrentBusinessGroup } from 'src/models/XptUsersModel';
import { MAX_FRACTIONAL_DIGITS_ALLOWED, roundToPrecision } from 'src/utils/ag-grid-utils';
import { convertMonthFormatToDisplay, getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { generateUniqueId } from 'src/utils/generic-utilities';
import { ForecastGridFixedFields } from './ForecastGridConstants';
import { isSegmentModified } from './ForecastTemplateUtils';
import ForecastValidationMessages from './ValidationMessages';

export const INITIAL_VALIDATION_STATUS: ForecastTemplateDataValidationStatus = {
  HeadersMatching: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Header validation' },
  BudgetOwnerValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Budget Owner validation' },
  CostCenterValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Cost Center validation' },
  ForecastMonthValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Forecast Month validation' },
  MandatoryFieldValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Mandatory fields validation' },
  NonEditableFieldValidations: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Non editable fields validation' },
  UnAuthorizedRows: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Budget owner validation' },
  SegmentsValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Segments validation' },
  DuplicateRecordValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Duplicate validation' },
  RepeatedRecordValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Repeated validation' },
  BudgetTypeAccountMappingValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Budget Type & Account validation' },
  BudgetOwnerCostCenterValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Budget Owner & CC validation' }
};

export const NO_MODIFIED_ROWS: ForecastTemplateDataValidationStatus = {
  HeadersMatching: {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: ForecastValidationMessages.HEADER_VALIDATION_SUCCESS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  BudgetOwnerValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  CostCenterValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  ForecastMonthValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  MandatoryFieldValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  NonEditableFieldValidations: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  SegmentsValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  UnAuthorizedRows: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  DuplicateRecordValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  RepeatedRecordValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  BudgetTypeAccountMappingValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  BudgetOwnerCostCenterValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  }
};

export const INITIAL_SUBMIT_STATUS: ValidationStatusEntity = {
  colorOverride: 'grey',
  validationStatus: 'pending',
  validationMessage: 'Not Initiated',
  validationDefaultMessage: '',
  validationErrorDetails: []
};

// Applicable to only file upload
export const transformUploadFileDisplayHeaderToModel = (forecastTemplateRelevantRowData: any[], forecastTemplateColumns: ForecastTemplateColumns) => {
  const lineItemIdDisplayField = ForecastGridFixedFields.XptLineItemId.displayName;
  const lineItemIdValue = ForecastGridFixedFields.XptLineItemId.value;

  const budgetOwnerDisplayField = ForecastGridFixedFields.BudgetOwner.displayName;
  const budgetOwnerValue = ForecastGridFixedFields.BudgetOwner.value;

  const budgetTypeDisplayField = ForecastGridFixedFields.BudgetType.displayName;
  const budgetTypeValue = ForecastGridFixedFields.BudgetType.value;

  const forecastMonthDisplayFields = forecastTemplateColumns.forecastMonthsDisplayFormat;
  const forecastMonthValues = forecastTemplateColumns.forecastMonthColumnsIds;

  const actualMonthDisplayFields = forecastTemplateColumns.actualMonthsDisplayFormat;
  const actualMonthValues = forecastTemplateColumns.actualMonthColumnIds;

  return forecastTemplateRelevantRowData.map((item) => {
    const transformedItem: any = {};

    transformedItem[lineItemIdValue] = item[lineItemIdDisplayField];
    transformedItem[budgetOwnerValue] = item[budgetOwnerDisplayField];
    transformedItem[budgetTypeValue] = item[budgetTypeDisplayField];

    forecastMonthDisplayFields.forEach((field, index) => {
      transformedItem[forecastMonthValues[index]] = item[field];
    });

    actualMonthDisplayFields.forEach((field, index) => {
      transformedItem[actualMonthValues[index]] = item[field];
    });

    // Include the rest of the fields that are not explicitly transformed
    Object.keys(item).forEach((key) => {
      if (![lineItemIdDisplayField, budgetOwnerDisplayField, ...forecastMonthDisplayFields, ...actualMonthDisplayFields].includes(key)) {
        transformedItem[key] = item[key];
      }
    });

    return transformedItem;
  });
};

/**
 * Transforms forecast month values in the provided row data by parsing strings to floats and handling null values.
 * Applicable only to file upload.
 *
 * @param {any[]} forecastTemplateRelevantRowData - The relevant row data from the forecast template.
 * @param {ForecastTemplateColumns} forecastTemplateColumns - The forecast template columns configuration.
 * @returns {Promise<{ transformedData: any[], validationStatus: ValidationStatusEntity }>} - The transformed data and the validation status of the transformation.
 */
export const transformForecastMonths = async (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns
): Promise<{ transformedData: any[]; validationStatus: ValidationStatusEntity }> => {
  const corpSegmentMandatoryFields = forecastTemplateColumns.corpSegmentMandatoryFields;
  const forecastMonthColumnsIds = forecastTemplateColumns.forecastMonthColumnsIds;
  const validationErrorDetails: ValidationErrorDetail[] = [];

  const transformedData = forecastTemplateRelevantRowData.map((row, rowIndex) => {
    const updatedRow = { ...row };

    // The corp segment values are stored as strings in the system, even though they may be numeric in nature.
    // This code ensures that the values are properly converted to strings before processing or storing them.
    corpSegmentMandatoryFields.forEach((column) => {
      updatedRow[column] = row[column] == null ? null : String(row[column]);
    });

    forecastMonthColumnsIds.forEach((column) => {
      const value = updatedRow[column];
      if (value === null || value === undefined || (typeof value === 'string' && value.trim() === '')) {
        updatedRow[column] = null;
      } else {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
          validationErrorDetails.push({
            rowIndex: rowIndex + 1,
            message: `Invalid number at column '${convertMonthFormatToDisplay(column)}' - ${updatedRow[column]} ${getLineItemIdMessagePart(row)}.`
          });
          updatedRow[column] = null;
        } else {
          // Rounding the value to the specified maximum number of fractional digits
          updatedRow[column] = roundToPrecision(parsedValue, MAX_FRACTIONAL_DIGITS_ALLOWED);
        }
      }
    });

    return updatedRow;
  });

  const validationStatus: ValidationStatusEntity =
    validationErrorDetails.length > 0
      ? {
          colorOverride: 'red',
          validationMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_FAILED,
          validationStatus: 'error',
          validationDefaultMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_DEFAULT,
          validationErrorDetails
        }
      : {
          colorOverride: 'green',
          validationMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_SUCCESS,
          validationStatus: 'success',
          validationDefaultMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_DEFAULT,
          validationErrorDetails: []
        };

  return { transformedData, validationStatus };
};

// Applicable to only file upload
// If XptLineItemId is empty or null, then it is considered as new row.
export const getModifiedRowsFromExcelFile = (
  forecastTemplateColumns: ForecastTemplateColumns,
  transformedUploadFileData: any[],
  forecastTemplateCompleteData: any[],
  userAlias: string,
  scenarioSeqId: number | null,
  accountBudgetTypeMapping: AccountBudgetTypeMapping[]
): any[] => {
  const modifiedRows: any[] = [];

  const forecastMonthColumnsIds = forecastTemplateColumns.forecastMonthColumnsIds;
  const corpSegmentFields = forecastTemplateColumns.corpSegmentMandatoryFields;

  try {
    transformedUploadFileData.forEach((fileRow) => {
      const isNewRow = !fileRow[ForecastGridFixedFields.XptLineItemId.value];

      if (isNewRow) {
        const accountCodeInNewRow = `${fileRow[CorpSegmentNames.ACCOUNT]}`;
        const correspondingBudgetType =
          accountBudgetTypeMapping.find((accountBudgetType) => accountBudgetType.account_code === accountCodeInNewRow)?.budget_type || null;
        // logger.info(`accountCodeInNewRow ${accountCodeInNewRow} and corresponding budget type is ${correspondingBudgetType}`);
        if (correspondingBudgetType === null) logger.warn(`Unable to find Budget Type for Account Code ${accountCodeInNewRow}`);

        // Define the common properties for the modified row
        const baseRow = {
          ...fileRow,
          [ForecastGridFixedFields.RowId.value]: generateUniqueId(),
          [ForecastGridFixedFields.ScenarioSeqId.value]: scenarioSeqId,
          [ForecastGridFixedFields.IsTouched.value]: true,
          [ForecastGridFixedFields.IsEdited.value]: true,
          [ForecastGridFixedFields.IsActive.value]: true,
          [ForecastGridFixedFields.IsNewFERow.value]: true,

          [ForecastGridFixedFields.BudgetType.value]: correspondingBudgetType,

          [ForecastGridFixedFields.UpdatedBy.value]: userAlias,
          [ForecastGridFixedFields.UpdatedAt.value]: getCurrentUTCTimeInISO()
        };

        // Define the condition-specific properties
        const additionalFields = {
          [ForecastGridFixedFields.XptLineItemId.value]: null,
          [ForecastGridFixedFields.XptLineItemSeqId.value]: null,
          [ForecastGridFixedFields.IsNew.value]: true,
          [ForecastGridFixedFields.IsSegmentEdited.value]: true
        };

        // Merge baseRow and additionalFields, then push to modifiedRows
        modifiedRows.push({ ...baseRow, ...additionalFields });
        return;
      }

      const correspondingRow = forecastTemplateCompleteData.find(
        (templateRow) => `${templateRow[ForecastGridFixedFields.XptLineItemId.value]}` === `${fileRow[ForecastGridFixedFields.XptLineItemId.value]}`
      );

      if (!correspondingRow) {
        throw new Error(`No corresponding row found for line item id ${fileRow[ForecastGridFixedFields.XptLineItemId.value]}`);
      }

      let isModified = false;
      // modifiedCheckFields: [lineItemIdValue, budgetOwnerValue, ...corpSegmentMandatoryFields, ...businessSegmentHeaders, ...forecastMonthIds]
      for (const checkField of forecastTemplateColumns.modifiedCheckFields) {
        let fileValue = fileRow[checkField] === '' || fileRow[checkField] === undefined ? null : fileRow[checkField];
        let templateValue = correspondingRow[checkField] === 0 || correspondingRow[checkField] ? correspondingRow[checkField] : null;
        // The corp segment values are stored as strings in the system, even though they may be numeric in nature.
        // This code ensures that the values are properly converted to strings before processing or storing them.
        if (corpSegmentFields.includes(checkField)) {
          fileValue = fileValue?.toString() ?? fileValue;
          templateValue = templateValue?.toString() ?? templateValue;
        }

        // Convert values to decimal with MAX_FRACTIONAL_DIGITS_ALLOWED fractional digits if the field is part of forecastMonthColumnsIds
        if (forecastMonthColumnsIds.includes(checkField)) {
          fileValue = fileValue !== null ? roundToPrecision(fileValue, MAX_FRACTIONAL_DIGITS_ALLOWED) : fileValue;
          templateValue = templateValue !== null ? roundToPrecision(templateValue, MAX_FRACTIONAL_DIGITS_ALLOWED) : templateValue;
        }

        // Coerce both values to string for comparison
        if (`${fileValue}` !== `${templateValue}`) {
          // console.debug('Modified file row ', JSON.stringify(fileRow));
          // console.debug('Modified original row ', JSON.stringify(correspondingRow));
          console.debug(
            `Modified field with line item id as ${
              correspondingRow[ForecastGridFixedFields.XptLineItemId.value]
            }: ${checkField}, File Value: ${fileValue}, Template Value: ${templateValue}`
          );
          isModified = true;
          break;
        }
      }

      if (isModified) {
        const isSegmentEdited = isSegmentModified(correspondingRow, fileRow, forecastTemplateColumns.corpAndBussSegmentsMandatoryFields);

        const modifiedAccountCode = `${fileRow[CorpSegmentNames.ACCOUNT]}`;
        const correspondingBudgetType =
          accountBudgetTypeMapping.find((accountBudgetType) => accountBudgetType.account_code === modifiedAccountCode)?.budget_type || null;
        // logger.info(`modifiedAccountCode ${modifiedAccountCode} and corresponding budget type is ${correspondingBudgetType}`);
        if (correspondingBudgetType === null) logger.warn(`Unable to find Budget Type for Account Code ${modifiedAccountCode}`);

        const modifiedRow = {
          ...fileRow,
          [ForecastGridFixedFields.RowId.value]: generateUniqueId(),

          [ForecastGridFixedFields.XptLineItemId.value]: correspondingRow[ForecastGridFixedFields.XptLineItemId.value],
          [ForecastGridFixedFields.XptLineItemSeqId.value]: correspondingRow[ForecastGridFixedFields.XptLineItemSeqId.value],
          [ForecastGridFixedFields.ScenarioSeqId.value]: scenarioSeqId,

          [ForecastGridFixedFields.IsNew.value]: correspondingRow[ForecastGridFixedFields.IsNew.value],
          [ForecastGridFixedFields.IsSegmentEdited.value]: isSegmentEdited,

          [ForecastGridFixedFields.IsNewFERow.value]: false,
          [ForecastGridFixedFields.IsTouched.value]: true,
          [ForecastGridFixedFields.IsEdited.value]: true,
          [ForecastGridFixedFields.IsActive.value]: true,

          [ForecastGridFixedFields.BudgetType.value]: correspondingBudgetType,

          [ForecastGridFixedFields.UpdatedBy.value]: userAlias,
          [ForecastGridFixedFields.UpdatedAt.value]: getCurrentUTCTimeInISO()
        };
        modifiedRows.push(modifiedRow);
      }
    });
  } catch (error: any) {
    logger.error('Error while comparing rows:', error);
  }

  return modifiedRows;
};

// Applicable to only file upload
/**
 * Validates that the headers match the expected headers in both presence and order.
 * @param {string[]} headerRow - The list of headers from the uploaded file.
 * @param {string[]} expectedHeaders - The expected list of headers.
 * @returns {Promise<ValidationStatusEntity>} - The validation status of the headers.
 */
export const validateHeaders = async (headerRow: string[], expectedHeaders: string[]): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];
  if (headerRow.length !== expectedHeaders.length) {
    validationErrorDetails.push({
      message: `Header mismatch.`
    });
  } else {
    headerRow.forEach((header, rowIndex) => {
      if (header !== expectedHeaders[rowIndex]) {
        validationErrorDetails.push({
          rowIndex: rowIndex + 1,
          message: `Header mismatch. Expected '${expectedHeaders[rowIndex]}', found '${header}'.`
        });
      }
    });
  }

  if (validationErrorDetails.length > 0) {
    console.debug(`Header validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationMessage: ForecastValidationMessages.HEADER_VALIDATION_FAILED,
      validationStatus: 'error',
      validationDefaultMessage: ForecastValidationMessages.HEADER_VALIDATION_DEFAULT_MESSAGE,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationMessage: ForecastValidationMessages.HEADER_VALIDATION_SUCCESS,
    validationStatus: 'success',
    validationDefaultMessage: ForecastValidationMessages.HEADER_VALIDATION_DEFAULT_MESSAGE,
    validationErrorDetails: []
  };
};

/**
 * Validates that all mandatory fields are present in each object of the file data.
 * @param {string[]} mandatoryFields - The array of mandatory field names.
 * @param {any[]} forecastTemplateRelevantRowData - The array of data objects from the file.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateMandatoryFields = async (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns
): Promise<ValidationStatusEntity> => {
  logger.info(`Validating Mandatory Fields for ${forecastTemplateRelevantRowData.length} records`);

  const validationErrorDetails: ValidationErrorDetail[] = [];
  const mandatoryFields = forecastTemplateColumns.mandatoryFields;

  // But to display the error message, we don't need to show Budget Owner & Description fields.
  // Basically Auto generated fields no need to show in error message.
  const corpSegments = forecastTemplateColumns.corpSegmentMandatoryFields?.filter((corpSegment) => !corpSegment.endsWith(' Description'));
  const businessSegments = forecastTemplateColumns.businessSegmentMandatoryFields?.filter(
    (businessSegment) => !businessSegment.endsWith(' Description')
  );
  const errorMessageMandatoryFields = corpSegments.concat(businessSegments);
  const defaultValidationMessage = ForecastValidationMessages.MANDATORY_FIELDS_VALIDATION_DEFAULT_MESSAGE(errorMessageMandatoryFields);

  forecastTemplateRelevantRowData.forEach((row, rowIndex) => {
    const missingFields = mandatoryFields.filter(
      (field) => !row.hasOwnProperty(field) || row[field] === null || row[field] === undefined || row[field] === ''
    );

    if (missingFields.length > 0) {
      missingFields.forEach((field) => {
        validationErrorDetails.push({
          message: `Required field ${field} ${getLineItemIdMessagePart(row)}`
        });
      });
    }
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Mandatory field check validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.MANDATORY_FIELDS_VALIDATION_FAILED,
      validationDefaultMessage: defaultValidationMessage,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: ForecastValidationMessages.MANDATORY_FIELDS_VALIDATION_SUCCESS,
    validationDefaultMessage: defaultValidationMessage,
    validationErrorDetails: []
  };
};

/**
 * Validates that non-editable fields have not been modified.
 * @param {any[]} forecastTemplateRelevantRowData - The array of data objects from the file.
 * @param {ForecastTemplateColumns} forecastTemplateColumns - The forecast template columns configuration.
 * @param {any[]} forecastTemplateCompleteData - The array of complete data objects from the forecast template.
 * @param {string} userAlias - The alias of the current user.
 * @param {boolean} isAdminUser - Flag indicating if the current user is an admin.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateNonEditableFields = async (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns,
  forecastTemplateCompleteData: any[],
  isBudgetLeaderOrAdmin: boolean
): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  const mandatoryFieldsWithoutBudgetOwner = forecastTemplateColumns.mandatoryFields.filter(
    (field) => field !== ForecastGridFixedFields.BudgetOwner.value
  );

  const mandatoryFields = forecastTemplateColumns.mandatoryFields;

  // Admins can edit Budget Owner. So, excluding the BudgetOwner from check for Admins.
  let nonEditableFieldsBasedOnRole: string[] = [];

  // Create a map for quick lookup
  const completeDataMap = new Map<string, any>();

  forecastTemplateCompleteData.forEach((row) => {
    const key = `${row[ForecastGridFixedFields.XptLineItemId.value]}`;
    completeDataMap.set(key, row);
  });

  forecastTemplateRelevantRowData.forEach((row) => {
    const lineItemId = row[ForecastGridFixedFields.XptLineItemId.value];

    // If there is no Line Item Id, then it is new row, all fields are editable.  skip further checks for this row
    if (!lineItemId) {
      return;
    }

    // Identifying nonEditableFieldsBasedOnRole
    const isNewRow = row[ForecastGridFixedFields.IsNew.value];
    // If it is New Row, Segments are editable
    if (isNewRow) {
      // But if user is not admin, then except BudgetOwner, remaining fields are editable
      if (!isBudgetLeaderOrAdmin) {
        nonEditableFieldsBasedOnRole = [ForecastGridFixedFields.BudgetOwner.value];
      } else {
        // if admin, all fields are editable
        nonEditableFieldsBasedOnRole = [];
      }
    } else {
      // If it is not a new row & not admin, all mandatory fields are non editable
      if (!isBudgetLeaderOrAdmin) {
        nonEditableFieldsBasedOnRole = mandatoryFields;
      } else {
        // If it is not a new row & admin, only mandatory fields are editable
        nonEditableFieldsBasedOnRole = mandatoryFieldsWithoutBudgetOwner;
      }
    }
    const matchingRow = completeDataMap.get(`${lineItemId}`);
    if (matchingRow) {
      // Check non-editable fields
      nonEditableFieldsBasedOnRole.forEach((field) => {
        if (row[field] !== matchingRow[field]) {
          validationErrorDetails.push({
            message: `${field} '${row[field]}' ${getLineItemIdMessagePart(row)} - Non-editable field`
          });
        }
      });
    }
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Non-editable field check validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_FAILED,
      validationDefaultMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_DEFAULT,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_SUCCESS,
    validationDefaultMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_DEFAULT,
    validationErrorDetails: []
  };
};

/**
 * Validates that the user has authorization to modify the rows.
 * @param {any[]} modifiedRows - The array of modified rows to validate.
 * @param {string} userAlias - The alias of the current user.
 * @param {boolean} isAdminUser - Whether the current user is an admin.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateUnAuthorizedRows = (modifiedRows: any[], userAlias: string, isAdminUser: boolean): Promise<ValidationStatusEntity> => {
  return new Promise((resolve) => {
    const validationErrorDetails: ValidationErrorDetail[] = [];

    modifiedRows.forEach((row, rowIndex) => {
      const budgetOwner = row[ForecastGridFixedFields.BudgetOwner.value];
      if (!isAdminUser && budgetOwner !== userAlias) {
        validationErrorDetails.push({
          rowIndex: rowIndex + 1,
          message: `Budget Owners can only modify their own rows. Expected Budget Owner: ${userAlias}. Found ${budgetOwner}  ${getLineItemIdMessagePart(
            row
          )}.`
        });
      }
    });

    if (validationErrorDetails.length > 0) {
      console.debug(`UnAuthorized check validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
      return resolve({
        colorOverride: 'red',
        validationStatus: 'error',
        validationMessage: ForecastValidationMessages.UNAUTHORIZED_ROWS_VALIDATION_FAILED,
        validationDefaultMessage: ForecastValidationMessages.UNAUTHORIZED_ROWS_VALIDATION_DEFAULT,
        validationErrorDetails
      });
    }

    resolve({
      colorOverride: 'green',
      validationStatus: 'success',
      validationMessage: ForecastValidationMessages.UNAUTHORIZED_ROWS_VALIDATION_SUCCESS,
      validationDefaultMessage: ForecastValidationMessages.UNAUTHORIZED_ROWS_VALIDATION_DEFAULT,
      validationErrorDetails: []
    });
  });
};

/**
 * Validates the corp segment and business segment fields in the modified rows.
 * @param {any[]} forecastTemplateRelevantRowData - The array of modified rows to validate.
 * @param {ForecastTemplateColumns} forecastTemplateColumns - The column definitions including segment fields.
 * @param {ForecastTemplateMasterCorpSegmentDropdownValues[]} corpSegmentDropdownValues - The array of master corp segment dropdown values.
 * @param {MasterBusinessSegments[]} masterBusinessSegments - The array of master business segments.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateSegments = (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns,
  corpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[],
  masterBusinessSegments: MasterBusinessSegments[],
  expenseTypesForCurrentGroup: string[]
): Promise<ValidationStatusEntity> => {
  return new Promise((resolve) => {
    const validationErrorDetails: ValidationErrorDetail[] = [];

    const { corpSegmentMandatoryFields, businessSegmentDropdownFields } = forecastTemplateColumns;
    const corpSegmentFieldsWithoutDescription = corpSegmentMandatoryFields.filter((field) => !field.endsWith(' Description'));

    forecastTemplateRelevantRowData
      .filter((rowData) => rowData[ForecastGridFixedFields.IsNewFERow.value] || rowData[ForecastGridFixedFields.IsSegmentEdited.value])
      .forEach((row, rowIndex) => {
        // Validate Corp Segment Fields
        corpSegmentFieldsWithoutDescription.forEach((field) => {
          const dropdownValue = corpSegmentDropdownValues.find((dropdown) => dropdown.masterCorpSegmentDisplayName === field);

          if (!dropdownValue) {
            validationErrorDetails.push({
              message: `Dropdown values for Corp Segment field: ${field} are missing.`
            });
            return;
          }

          const isValid = dropdownValue.masterCorpSegmentDropdownValues.some((option) => option.label === row[field]);

          if (!isValid) {
            validationErrorDetails.push({
              message: `Invalid value ${row[field] ? `'${row[field]}'` : ' '} for ${field} - ${getLineItemIdMessagePart(row)}`
            });
          }
        });

        // Validate Business Segment Fields
        businessSegmentDropdownFields.forEach((field) => {
          const masterSegment = masterBusinessSegments.find((segment) => segment.segment_name === field);

          if (!masterSegment) {
            validationErrorDetails.push({
              message: `Dropdown values for Business Segment field: ${field} are missing.`
            });
            return;
          }

          const isValid =
            field === BusinessSegmentNames.EXPENSE_TYPE
              ? expenseTypesForCurrentGroup.some((expenseType) => expenseType === row[field])
              : masterSegment.business_segment_dropdown_list.some((option) => option === row[field]);

          if (!isValid) {
            validationErrorDetails.push({
              message: `Invalid value ${row[field] ? `'${row[field]}'` : ' '} for ${field} - ${getLineItemIdMessagePart(row)}`
            });
          }
        });
      });

    if (validationErrorDetails.length > 0) {
      console.debug(`Segment Validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
      resolve({
        colorOverride: 'red',
        validationStatus: 'error',
        validationMessage: ForecastValidationMessages.SEGMENTS_VALIDATION_IN_FAILED,
        validationDefaultMessage: ForecastValidationMessages.SEGMENTS_VALIDATION_DEFAULT,
        validationErrorDetails
      });
    } else {
      resolve({
        colorOverride: 'green',
        validationStatus: 'success',
        validationMessage: ForecastValidationMessages.SEGMENTS_VALIDATION_IN_SUCCESS,
        validationDefaultMessage: ForecastValidationMessages.SEGMENTS_VALIDATION_DEFAULT,
        validationErrorDetails: []
      });
    }
  });
};

/**
 * Checks for duplicate records based on the combination of All the Corp Segment Fields & Business Segment ID Fields. (Doesn't consider Budget Owner while checking for duplicate records)
 * @param {any[]} forecastTemplateRelevantRowData - An array of relevant rows from the forecast template to be validated.
 * @param {ForecastTemplateColumns} forecastTemplateColumns -  An object containing column definitions, specifically corpSegmentMandatoryFields and businessSegmentMandatoryFields.
 * @param {any[]} forecastTemplateCompleteData - An array of all rows from the forecast template for checking duplicates against.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateDuplicateRecordsWhileUpload = async (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns,
  forecastTemplateCompleteData: any[]
): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  logger.info(
    `validateDuplicateRecordsWhileUpload : Validating Records ${forecastTemplateRelevantRowData.length} against complete set ${forecastTemplateCompleteData.length}`
  );
  const corpSegmentFieldsWithoutDescription = forecastTemplateColumns.corpSegmentMandatoryFields.filter((field) => !field.endsWith(' Description'));
  // Combine all the Corporate Segment Fields and Business Segment ID Fields into a single array.
  const idColumns = corpSegmentFieldsWithoutDescription.concat(forecastTemplateColumns.businessSegmentMandatoryFields);
  logger.info(`validateDuplicateRecordsWhileUpload: Validating for columns ${idColumns} `);
  // Iterate over each row in the relevant row data.
  forecastTemplateRelevantRowData.forEach((row, rowIndex) => {
    // Check if there is any existing row in the complete data that matches the current row.
    forecastTemplateCompleteData.some((existingRow) => {
      // Check if all the ID columns match between the current row and the existing row.
      const allColumnsMatch = idColumns.every((column) => row[column] === existingRow[column]);

      if (allColumnsMatch) {
        validationErrorDetails.push({
          rowIndex: rowIndex + 1,
          message: `Record already exist ${getLineItemIdMessagePart(row)}`
        });
        return true;
      }
      return false;
    });
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Checking if records already exist Validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.EXISTING_RECORDS_FOUND,
      validationDefaultMessage: ForecastValidationMessages.EXISTING_RECORDS_VALIDATION_DEFAULT,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: ForecastValidationMessages.NO_EXISTING_RECORDS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  };
};

/**
 * Checks for duplicate records based on the combination of all the Corp Segment Fields & Business Segment ID Fields within the relevant row data.
 * (Doesn't consider Budget Owner while checking for duplicate records)
 * @param {any[]} forecastTemplateRelevantRowData - An array of relevant rows from the forecast template to be validated.
 * @param {ForecastTemplateColumns} forecastTemplateColumns - An object containing column definitions, specifically corpSegmentMandatoryFields and businessSegmentMandatoryFields.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateDuplicateRecords = async (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns
): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  const corpSegmentFieldsWithoutDescription = forecastTemplateColumns.corpSegmentMandatoryFields.filter((field) => !field.endsWith(' Description'));
  // Combine all the Corporate Segment Fields and Business Segment ID Fields into a single array.
  const idColumns = corpSegmentFieldsWithoutDescription.concat(forecastTemplateColumns.businessSegmentMandatoryFields);

  // Set to keep track of unique combinations
  const uniqueCombinations = new Set<string>();

  // Iterate over each row in the relevant row data.
  forecastTemplateRelevantRowData.forEach((row, rowIndex) => {
    // Create a unique key for the combination of all ID columns
    const combinationKey = idColumns.map((column) => row[column]).join('|');

    // Check if the combination key already exists in the set
    if (uniqueCombinations.has(combinationKey)) {
      validationErrorDetails.push({
        rowIndex: rowIndex + 1,
        message: `Duplicate record found ${getLineItemIdMessagePart(row)}`
      });
    } else {
      uniqueCombinations.add(combinationKey);
    }
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Duplicate Validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.DUPLICATE_RECORDS_FOUND,
      validationDefaultMessage: ForecastValidationMessages.DUPLICATE_RECORDS_VALIDATION_DEFAULT,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: ForecastValidationMessages.NO_DUPLICATE_RECORDS,
    validationDefaultMessage: ForecastValidationMessages.DUPLICATE_RECORDS_VALIDATION_DEFAULT,
    validationErrorDetails: []
  };
};

export const getLineItemIdMessagePart = (row: any): string => {
  const lineItemId = row[ForecastGridFixedFields.XptLineItemId.value];
  return lineItemId ? `(ID: ${lineItemId})` : '';
};

export const validateBudgetTypeAndAccountCombination = async (
  forecastTemplateRelevantRowData: ForecastGridRowData[],
  accountBudgetTypeMapping: AccountBudgetTypeMapping[]
): Promise<ValidationStatusEntity> => {
  try {
    const validationErrorDetails: ValidationErrorDetail[] = [];

    forecastTemplateRelevantRowData.forEach((row, rowIndex) => {
      const accountCode = row[CorpSegmentNames.ACCOUNT];
      const budgetType = row[ForecastGridFixedFields.BudgetType.value];

      const accountBudgetTypeEntry = accountBudgetTypeMapping.find((entry) => entry.account_code === accountCode);

      if (!accountBudgetTypeEntry) {
        validationErrorDetails.push({
          rowIndex: rowIndex + 1,
          message: `Account ${accountCode} not found in the mapping ${getLineItemIdMessagePart(row)}`
        });
      }

      if (accountBudgetTypeEntry && accountBudgetTypeEntry.budget_type !== budgetType) {
        validationErrorDetails.push({
          rowIndex: rowIndex + 1,
          message: `Budget Type ${budgetType} is not valid for Account ${accountCode} ${getLineItemIdMessagePart(row)}`
        });
      }
    });

    if (validationErrorDetails.length > 0) {
      console.debug(`Budget Type & Account Mapping validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
      return {
        colorOverride: 'red',
        validationStatus: 'error',
        validationMessage: ForecastValidationMessages.ACCOUNT_BUDGET_TYPE_MAPPING_VALIDATION_FAILED,
        validationDefaultMessage: ForecastValidationMessages.ACCOUNT_BUDGET_TYPE_MAPPING_VALIDATION_DEFAULT,
        validationErrorDetails
      };
    }

    return {
      colorOverride: 'green',
      validationStatus: 'success',
      validationMessage: ForecastValidationMessages.ACCOUNT_BUDGET_TYPE_MAPPING_VALIDATION_SUCCESS,
      validationDefaultMessage: ForecastValidationMessages.ACCOUNT_BUDGET_TYPE_MAPPING_VALIDATION_DEFAULT,
      validationErrorDetails: []
    };
  } catch (error: any) {
    logger.error('Error validating Budget Type & Account Mapping:', error);
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.ACCOUNT_BUDGET_TYPE_MAPPING_VALIDATION_ERROR,
      validationDefaultMessage: ForecastValidationMessages.ACCOUNT_BUDGET_TYPE_MAPPING_VALIDATION_DEFAULT,
      validationErrorDetails: []
    };
  }
};

export const validateCCsInvolvedInExcel = async (
  forecastTemplateGridData: ForecastGridRowData[],
  userAccessForCurrentBusinessGroup: UserAccessForCurrentBusinessGroup
): Promise<ValidationStatusEntity> => {
  try {
    const currentUserCCs = new Set(getUserCostCenters(userAccessForCurrentBusinessGroup));
    const costCentersFromUploadingFile = new Set<string>();

    forecastTemplateGridData.forEach((row) => {
      const costCenter = `${row[CorpSegmentNames.COST_CENTER]}`;
      if (costCenter) costCentersFromUploadingFile.add(costCenter);
    });

    const invalidCCsFromFile = Array.from(costCentersFromUploadingFile).filter((cc) => !currentUserCCs.has(cc));

    if (invalidCCsFromFile.length > 0) {
      console.debug("Cost Center's validation failed.", JSON.stringify(invalidCCsFromFile, null, 2));
      return createCCValidationStatus('error', ForecastValidationMessages.CC_AUTHORIZATION_VALIDATION_FAILED, invalidCCsFromFile);
    }

    return createCCValidationStatus('success', ForecastValidationMessages.CC_AUTHORIZATION_VALIDATION_SUCCESS);
  } catch (error: any) {
    logger.error('Error validating Cost Centers:', error);
    return createCCValidationStatus('error', ForecastValidationMessages.CC_AUTHORIZATION_VALIDATION_ERROR);
  }
};

const createCCValidationStatus = (status: 'error' | 'success', message: string, invalidCCs: string[] = []): ValidationStatusEntity => ({
  colorOverride: status === 'error' ? 'red' : 'green',
  validationStatus: status,
  validationMessage: message,
  validationDefaultMessage: ForecastValidationMessages.CC_AUTHORIZATION_VALIDATION_DEFAULT,
  validationErrorDetails: invalidCCs.map((cc) => ({ message: cc }))
});

export const validateBudgetOwnersInvolvedInExcel = async (
  forecastTemplateGridData: ForecastGridRowData[],
  userAccessForCurrentBusinessGroup: UserAccessForCurrentBusinessGroup
): Promise<ValidationStatusEntity> => {
  try {
    if (!userAccessForCurrentBusinessGroup.isBudgetOwner) {
      return createBudgetOwnerValidationStatus('success', ForecastValidationMessages.BUDGET_OWNER_VALIDATION_SUCCESS);
    } else {
      const budgetOwnersFromUploadingFile = new Set<string>();
      const currentUser = userAccessForCurrentBusinessGroup.user_alias;

      forecastTemplateGridData.forEach((row) => {
        const budgetOwnerInThisRow = row[ForecastGridFixedFields.BudgetOwner.value];
        if (budgetOwnerInThisRow) budgetOwnersFromUploadingFile.add(budgetOwnerInThisRow);
      });

      const invalidBudgetOwnersFromFile = Array.from(budgetOwnersFromUploadingFile).filter((budgetOwner) => currentUser !== budgetOwner);

      if (invalidBudgetOwnersFromFile.length > 0) {
        console.debug("Budget Owner's validation failed.", JSON.stringify(invalidBudgetOwnersFromFile, null, 2));
        return createBudgetOwnerValidationStatus('error', ForecastValidationMessages.BUDGET_OWNER_VALIDATION_FAILED, invalidBudgetOwnersFromFile);
      } else {
        return createBudgetOwnerValidationStatus('success', ForecastValidationMessages.BUDGET_OWNER_VALIDATION_SUCCESS);
      }
    }
  } catch (error: any) {
    logger.error('Error validating Cost Centers:', error);
    return createBudgetOwnerValidationStatus('error', ForecastValidationMessages.BUDGET_OWNER_VALIDATION_ERROR);
  }
};

const createBudgetOwnerValidationStatus = (
  status: 'error' | 'success',
  message: string,
  invalidBudgetOwners: string[] = []
): ValidationStatusEntity => ({
  colorOverride: status === 'error' ? 'red' : 'green',
  validationStatus: status,
  validationMessage: message,
  validationDefaultMessage: ForecastValidationMessages.BUDGET_OWNER_VALIDATION_DEFAULT,
  validationErrorDetails: invalidBudgetOwners.map((bo) => ({ message: bo }))
});

export const validateBudgetOwnerAndCostCenterCombination = async (
  forecastTemplateGridData: ForecastGridRowData[],
  xptUsersOfCurrentDataClassification: UserAccessEntity[],
  userAccessForCurrentBusinessGroup: UserAccessForCurrentBusinessGroup
): Promise<ValidationStatusEntity> => {
  try {
    const validationErrorDetails: ValidationErrorDetail[] = [];
    const currentUserCCs = getUserCostCenters(userAccessForCurrentBusinessGroup);
    forecastTemplateGridData.forEach((row, rowIndex) => {
      const budgetOwner = `${row[ForecastGridFixedFields.BudgetOwner.value]}`;
      const costCenter = `${row[CorpSegmentNames.COST_CENTER]}`;

      // If Budget Owner field is current user, then check with current users CC's. (No need to search in UserAccessList)
      if (budgetOwner === userAccessForCurrentBusinessGroup.user_alias) {
        if (!currentUserCCs.includes(costCenter)) {
          validationErrorDetails.push({
            rowIndex: rowIndex + 1,
            message: `Budget Owner ${budgetOwner} and Cost Center ${costCenter} combination is invalid.`
          });
        }
      } else {
        if (budgetOwner && costCenter) {
          const givenUsersCCs = getUserCostCentersFromUserAccessEntitiesList(budgetOwner, xptUsersOfCurrentDataClassification);
          if (!givenUsersCCs.includes(costCenter)) {
            validationErrorDetails.push({
              rowIndex: rowIndex + 1,
              message: `Budget Owner ${budgetOwner} and Cost Center ${costCenter} combination is invalid.`
            });
          }
        }
      }
    });

    if (validationErrorDetails.length > 0) {
      console.debug(`Budget Owner & Cost Center Mapping validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
      return {
        colorOverride: 'red',
        validationStatus: 'error',
        validationMessage: ForecastValidationMessages.BUDGET_OWNER_AND_COST_CENTER_VALIDATION_FAILED,
        validationDefaultMessage: ForecastValidationMessages.BUDGET_OWNER_AND_COST_CENTER_VALIDATION_DEFAULT,
        validationErrorDetails
      };
    }

    return {
      colorOverride: 'green',
      validationStatus: 'success',
      validationMessage: ForecastValidationMessages.BUDGET_OWNER_AND_COST_CENTER_VALIDATION_SUCCESS,
      validationDefaultMessage: ForecastValidationMessages.BUDGET_OWNER_AND_COST_CENTER_VALIDATION_DEFAULT,
      validationErrorDetails: []
    };
  } catch (error: any) {
    logger.error('Error validating Budget Owner & Cost Center Mapping:', error);
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.BUDGET_OWNER_AND_COST_CENTER_VALIDATION_ERROR,
      validationDefaultMessage: ForecastValidationMessages.BUDGET_OWNER_AND_COST_CENTER_VALIDATION_DEFAULT,
      validationErrorDetails: []
    };
  }
};
